<template>
  <div>
    <!--Credential validation-->
    <b-card
      v-if="!loadStarted"
    >
      <b-alert

        show
        variant="warning"
      >
        <div class="alert-body">
          <feather-icon
            icon="InfoIcon"
            class="mr-50 mt-25"
          />
          {{ $t('confront.load.check.scanUniqueBadge') }}
        </div>
      </b-alert>
      <qrcode-stream
        @decode="onDecodeQr"
        @init="onInit"
      />
      <b-alert
        v-if="invalidRead"
        show
        variant="info"
      >
        <div class="alert-body">
          <feather-icon
            icon="InfoIcon"
            class="mr-50 mt-25"
          />
          {{ $t('confront.load.check.invalidBadge') }}
        </div>
      </b-alert>
    </b-card>
    <!--End credential read-->
    <b-card
      v-if="loadStarted && completedLoad === false"
    >
      <b-card-header>
        {{ $t('confront.load.check.confrontationGuidesPackages') }}
      </b-card-header>
      <b-card-body>
        <validation-observer
          #default="{ handleSubmit }"
          ref="refFormObserver"
        >
          <b-form
            @submit.prevent="handleSubmit(onSubmit)"
          >
            <!--Guia-->
            <validation-provider
              #default="validationContext"
              name="Guia"
              rules="required"
            >
              <b-form-group
                :label="$t('confront.load.check.houseGuide')"
                :label-for="$t('confront.load.check.houseGuide')"
              >
                <b-form-input
                  id="guia"
                  v-model="awb"
                  :disabled="completedLoad"
                  :state="getValidationState(validationContext)"
                />
                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>

            <!--Bultos-->
            <validation-provider
              #default="validationContext"
              name="Bulto"
              rules="required"
            >
              <b-form-group
                :label="$t('confront.load.check.package')"
                :label-for="$t('confront.load.check.package')"
              >
                <b-form-input
                  id="bulto"
                  v-model="pack"
                  :disabled="completedLoad"
                  :state="getValidationState(validationContext)"
                />
                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
            <b-button
              v-if="!completedLoad"
              variant="primary"
              class="mb-1 mb-sm-0 mr-0 mr-sm-1"
              type="submit"
              :disabled="completedLoad"
              :block="$store.getters['app/currentBreakPoint'] === 'xs'"
            >
              {{ $t('confront.load.check.confrontBulge') }}
            </b-button>
          </b-form>
        </validation-observer>
        <app-collapse>
          <app-collapse-item title="Detalles">
            <b-table
              ref="refTable"
              :items="load.relacionDocumentoOperaciones"
              :fields="tableFields"
              responsive
            >
              <template #cell(estado)="data">
                <div
                  v-if="data.item.confrontado"
                  class="text-success"
                >
                  {{ $t('confront.load.check.confronted') }}
                </div>
                <div
                  v-if="!data.item.confrontado"
                  class="text-warning"
                >
                  {{ $t('confront.load.check.confrontPending') }}
                </div>
              </template>
            </b-table>
          </app-collapse-item>
        </app-collapse>
      </b-card-body>
    </b-card>
    <b-card
      v-if="completedLoad"
    >
      <b-card-header>
        <b-alert
          show
          variant="warning"
        >
          <div class="alert-body">
            <feather-icon
              icon="InfoIcon"
              class="mr-50 mt-25"
            />
            {{ $t('confront.load.check.takePicture') }}
          </div>
        </b-alert>
      </b-card-header>
      <b-card-body>
        <b-img
          v-if="showCloseImage"
          class="img-confront-load"
          :src="closeImageUrl"
          fluid-grow
          alt="Responsive image"
        />
        <file-uploader
          v-model="load.confrontaCargaGuid"
          :file-name.sync="load.confrontaCargaArchivo"
          @on-upload-file="onUploadFile"
        />
        <b-button
          class="mt-2"
          variant="primary"
          :disabled="load.confrontaCargaGuid === undefined || load.confrontaCargaGuid === ''"
          @click="onCloseLoad"
        >
          <feather-icon
            icon="SendIcon"
          />
          {{ $t('confront.load.check.concludeUpload') }}
        </b-button>
      </b-card-body>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BCardHeader,
  BCardBody,
  BAlert,
  BForm,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BTable,
  BButton,
  BImg,
} from 'bootstrap-vue'
import { ref, watch } from '@vue/composition-api'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required } from '@validations'
import formValidation from '@core/comp-functions/forms/form-validation'
import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'
import { QrcodeStream } from 'vue-qrcode-reader'
import router from '@/router'
import i18n from '@/libs/i18n'
import common from '@/libs/common'
import FileUploader from '@/components/FileUploader.vue'
import confrontService from '@/services/confront.service'

export default {
  components: {
    BCard,
    BCardHeader,
    BCardBody,
    BAlert,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    AppCollapse,
    AppCollapseItem,
    BTable,
    BButton,
    BImg,
    ValidationProvider,
    ValidationObserver,

    QrcodeStream,

    FileUploader,
  },
  setup() {
    /* Services */
    const { updateLoad } = confrontService()
    const { toastSuccess, toastError, toastInfo } = common()
    /* Data */
    const load = ref(router.currentRoute.params.load)
    load.value.confrontaCargaGuid = ''
    const loadStarted = ref(false)
    const invalidRead = ref(false)
    const completedLoad = ref(false)
    const closeImageUrl = ref('')
    const showCloseImage = ref(false)
    const awb = ref('')
    const pack = ref('')

    /* Validation */
    const {
      refFormObserver,
      getValidationState,
      resetForm,
    } = formValidation({})
    /* UI */
    const paintOutline = (detectedCodes, ctx) => {
      // eslint-disable-next-line no-restricted-syntax
      for (const detectedCode of detectedCodes) {
        const [firstPoint, ...otherPoints] = detectedCode.cornerPoints
        // eslint-disable-next-line no-param-reassign
        ctx.strokeStyle = 'red'

        ctx.beginPath()
        ctx.moveTo(firstPoint.x, firstPoint.y)
        // eslint-disable-next-line no-restricted-syntax
        for (const { x, y } of otherPoints) {
          ctx.lineTo(x, y)
        }
        ctx.lineTo(firstPoint.x, firstPoint.y)
        ctx.closePath()
        ctx.stroke()
      }
    }
    const onInit = async promise => {
      try {
        await promise
      } catch (error) {
        if (error.name === 'NotAllowedError') {
          this.error = i18n.t('confront.load.check.errorPermissionCamera')
        } else if (error.name === 'NotFoundError') {
          this.error = i18n.t('confront.load.check.errorNoCamera')
        } else if (error.name === 'NotSupportedError') {
          this.error = i18n.t('confront.load.check.errorSecureContext')
        } else if (error.name === 'NotReadableError') {
          this.error = i18n.t('confront.load.check.errorCameraUsing')
        } else if (error.name === 'OverconstrainedError') {
          this.error = i18n.t('confront.load.check.errorInstalledCamera')
        } else if (error.name === 'StreamApiNotSupportedError') {
          this.error = i18n.t('confront.load.check.errorStreamApi')
        } else if (error.name === 'InsecureContextError') {
          this.error = i18n.t('confront.load.check.errorCameraAccess')
        } else {
          this.error = `ERROR: Camera error (${error.name})`
        }
      }
    }

    const tableFields = [
      // { key: 'guiaMaster', label: 'Master' },
      { key: 'guiaHouse', label: i18n.t('confront.load.check.labelHouse') },
      { key: 'bulto', label: i18n.t('confront.load.check.labelPackage') },
      { key: 'estado', label: i18n.t('confront.load.check.status') },
    ]
    const refTable = ref(null)
    /* Events */
    const onDecodeQr = result => {
      const satNumber = result.substring(result.indexOf('&bn=') + 4, result.indexOf('&d='))
      loadStarted.value = load.value.gafeteUnico === satNumber
      invalidRead.value = load.value.gafeteUnico !== satNumber
    }

    const onSubmit = () => {
      const records = load.value.relacionDocumentoOperaciones
      const [typedPack] = records.filter(f => (f.guiaHouse === awb.value || f.guiaMaster === awb.value) && f.bulto.toString() === pack.value)

      if (typedPack) {
        if (typedPack.confrontado) {
          toastInfo(i18n.t('confront.load.check.lumpPreviouslyConfronted'))
        } else {
          typedPack.confrontado = true
          toastSuccess(i18n.t('confront.load.check.lumpConfronted'))
        }
      } else {
        toastError(i18n.t('confront.load.check.wrongGuide'))
      }

      completedLoad.value = records.filter(f => f.confrontado).length === records.length
      refTable.value.refresh()

      awb.value = ''
      pack.value = ''
      refFormObserver.value.reset()
    }
    const onUploadFile = () => {
      closeImageUrl.value = `${process.env.VUE_APP_ADUACORE_API_URL}/api/Archivos/${load.value.confrontaCargaGuid}`
      showCloseImage.value = true
    }

    watch([load], () => {
      if (load.value.confrontaCargaGuid !== '') {
        closeImageUrl.value = `${process.env.VUE_APP_ADUACORE_API_URL}/api/Archivos/${load.value.confrontaCargaGuid}`
        showCloseImage.value = true
      } else {
        showCloseImage.value = false
      }
    })

    const onCloseLoad = () => {
      updateLoad(load.value, () => {
        router.push({ name: 'apps-confronts-load-list' })
      })
    }

    return {
      // Data
      load,
      loadStarted,
      invalidRead,
      completedLoad,
      awb,
      pack,
      closeImageUrl,
      showCloseImage,
      // UI
      paintOutline,
      onInit,
      onDecodeQr,
      tableFields,
      refTable,
      // Events
      onSubmit,
      // onLoad,
      onCloseLoad,
      onUploadFile,
      // Validation
      refFormObserver,
      getValidationState,
      resetForm,
      required,
    }
  },
}
</script>

<style>

</style>

import axios from '@axios'
import common from '@/libs/common'

export default function confrontService() {
  const { throwError, throwSuccess } = common()
  const API_URL = `${process.env.VUE_APP_ADUACORE_API_URL}/api`

  /* Standard prefix names:
    Post: create, Get: fetch,
    Put: update,  Patch: partialUpdate,
    Delete: delete
  */
  const fetchConfronts = control => new Promise((resolve, reject) => {
    axios
      .get(`${API_URL}/Confrontas/Almacen/${control}`)
      .then(response => {
        resolve(response.data)
      })
      .catch(error => {
        throwError(error)
        reject()
      })
  })

  const updateConfront = (data, callback) => {
    axios
      .put(`${API_URL}/Confrontas/Almacen/${data.relacionDocumentoId}`, data)
      .then(response => {
        throwSuccess(response)
        callback()
      })
      .catch(error => throwError(error))
  }

  const fetchLoads = () => new Promise((resolve, reject) => {
    axios
      .get(`${API_URL}/Confrontas/Carga`)
      .then(response => {
        resolve(response.data)
      })
      .catch(error => {
        throwError(error)
        reject()
      })
  })

  const updateLoad = (data, callback) => {
    axios
      .put(`${API_URL}/Confrontas/Carga/${data.relacionDocumentoId}`, data)
      .then(response => {
        throwSuccess(response)
        callback()
      })
      .catch(error => throwError(error))
  }

  const fetchValidateUniqueCredential = (control, gatefeteUnico, callback) => {
    axios
      .get(`${API_URL}/Confrontas/Carga/${control}/GafeteUnico/${gatefeteUnico}`)
      .then(response => {
        callback(response.data)
      })
      .catch(error => {
        throwError(error)
      })
  }

  return {
    fetchConfronts,
    updateConfront,
    fetchLoads,
    updateLoad,
    fetchValidateUniqueCredential,
  }
}
